<template>
  <Bread BreadTitle="三十五佛名號"></Bread>
  <div
    id="carouselExampleIndicators"
    class="carousel slide"
    data-ride="carousel"
  >
    <ol class="carousel-indicators">
      <li
        data-target="#carouselExampleIndicators"
        data-slide-to="0"
        class="active"
      ></li>

      <li
        data-target="#carouselExampleIndicators"
        :data-slide-to="n"
        v-for="(n, index) in navIndicators"
        :key="index"
      ></li>
    </ol>

    <div class="carousel-inner">
      <div class="carousel-item active">
        <img class="d-block w-100" src="/images/01F.jpg" alt="01" title="01" />
      </div>
      <div class="carousel-item" v-for="(n, index) in navPic" :key="index">
        <img class="d-block w-100" :src="GetPicUrl(n)" :alt="n" :title="n" />
      </div>
    </div>
    <a
      class="carousel-control-prev"
      href="#carouselExampleIndicators"
      role="button"
      data-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a
      class="carousel-control-next"
      href="#carouselExampleIndicators"
      role="button"
      data-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</template>

<script>
import { computed } from "vue";
export default {
  setup() {
    //const navPic = reactive(["02", "03", "04", "05", "06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28"]);
    const navPic = computed(() => {
      let vResult = [];
      for (var i = 2; i <= 35; i++) {
        vResult.push(("0" + i.toString()).substr(-2, 2));
      }
      return vResult;
    });

    const navIndicators = computed(() => {
      let vResult = [];
      for (var i = 1; i <= 34; i++) {
        vResult.push(i);
      }
      return vResult;
    });
    function GetPicUrl(n) {
      return "/images/" + n + "F.jpg";
    }
    return { navPic, GetPicUrl, navIndicators };
  },
};
</script>

<style>
</style>